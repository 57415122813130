import React, { useRef, useState } from "react";
import axios from "axios"
import toast, { Toaster } from 'react-hot-toast';
import "./Hero.css";
import close from "../../assets/close-circle.png";
import brochure from "../../assets/Harvey Properties Brochure.pdf";

const Hero = () => {
  const [isOverlayVisible, setOverlayVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const notifySuccess = () => toast.success('Form Submitted Successfully');
  const notifyFailed = () => toast.error('Form Submittion failed');

  const handleOverlayToggle = () => {
    setOverlayVisibility(!isOverlayVisible);
  };

  const [fullName,setFullName] = useState()
  const [phoneNumber,setPhoneNumber] = useState()
  const [emailAddress,setEmailAddress] = useState()

  const formData = {
    fullName,phoneNumber,emailAddress
  }


  const handleDownload = () =>{
    const link = document.createElement("a");
    link.href = brochure;
    link.download = "Harvey_Properties_Brochure.pdf";
    document.body.appendChild(link);
    document.body.appendChild(link);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }
  
  const handleFormSubmit = (e)=>{
    e.preventDefault();
    setIsLoading(true);
    axios.post("https://harveyvillage-5460b-default-rtdb.firebaseio.com/users", formData)
      .then(res => {
        console.log(res.data);
        notifySuccess()
      })
      .then(() => {
        // Trigger file download
        handleDownload();

      })
      .then(() => {
        setOverlayVisibility(false);
        setIsLoading(false)
        notifySuccess()
      })
      .catch(error => {
        console.error('Error sending email:', error);
        setIsLoading(false)
        setOverlayVisibility(false);
        notifyFailed()
      });
  }


  
  
  return (
    <section className="hero" id="hero">
      <Toaster />
      <div className="heroContainer">
        <div className="heroContent">
          <div className="heroTxt">
            <h1>Harvey Village</h1>
            <p>Embrace Serenity and Community</p>
          </div>
          <button className="button" onClick={handleOverlayToggle}>
            Download Brochure
          </button>
        </div>
      </div>
      {isOverlayVisible && (
        <div className="overlay">
          <div className="form-container">
            <div className="form-header">
              <p>Brochure Download Request</p>
              <button type="button" onClick={handleOverlayToggle}>
                <img src={close} alt="close icon" />
              </button>
            </div>

            <form onSubmit={handleFormSubmit}>
              <div className="form-content">
                <label htmlFor="fullName">Full Name</label>
                <input
                  type="text"
                  name="fullName"
                  id="fullName"
                  className="form-input"
                  placeholder="Enter your Full Name"
                  value={fullName}
                  onChange={(e)=>{setFullName(e.target.value)}}
                  required
                />
              </div>
              <div className="form-content">
                <label htmlFor="phoneNumber">Phone Number</label>
                <input
                  type="tel"
                  name="phoneNumber"
                  id="phoneNumber"
                  className="form-input"
                  placeholder="Enter your Phone Number"
                  required
                  value={phoneNumber}
                  onChange={(e)=>{setPhoneNumber(e.target.value)}}

                />
              </div>
              <div className="form-content">
                <label htmlFor="emailAddress">Email Address</label>
                <input
                  type="email"
                  name="emailAddress"
                  id="emailAddress"
                  className="form-input"
                  placeholder="Enter your Email Adress"
                  required
                  value={emailAddress}
                  onChange={(e)=>{setEmailAddress(e.target.value)}}

                />
              </div>
              <button type="submit" disabled={isLoading} className="submit-btn">
              {isLoading ? 'Submitting...' : 'Submit Request'}
              </button>
            </form>
          </div>
        </div>
      )}
    </section>
  );
};

export default Hero;
