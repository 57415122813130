import React, { useEffect, useRef, useState } from "react";
import "./Timer.css";

function Timer() {
  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");

  let interval = useRef();

  const startTimer = () => {
    const countdownDate = new Date("December 31, 2024 00:00:00").getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        //stop timer
        clearInterval(interval.current);
      } else {
        //update timer
        setTimerDays(days > 9 ? days : "0" + days);
        setTimerHours(hours > 9 ? hours : "0" + hours);
        setTimerMinutes(minutes > 9 ? minutes : "0" + minutes);
        setTimerSeconds(seconds > 9 ? seconds : "0" + seconds);
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();
  });

  return (
    <section className="timer">
      <div className="timerContainer">
        <h2 className="header">Get Ready To Move In!</h2>
        <div className="timesContainer">
          <div className="times">
            <h1>{timerDays}</h1>
            <p>DAYS</p>
          </div>

          <div className="times">
            <h1>{timerHours}</h1>
            <p>HOURS</p>
          </div>

          <div className="times">
            <h1>{timerMinutes}</h1>
            <p>MINUTES</p>
          </div>

          <div className="times">
            <h1>{timerSeconds}</h1>
            <p>SECONDS</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Timer;
