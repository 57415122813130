import React, { useState, useEffect } from "react";
import "./App.css";
import Header from "./components/Header/Header.jsx";
import Hero from "./components/Hero/Hero";
import Community from "./components/Community/Community";
import Architecture from "./components/Architecture/Architecture";
import Location from "./components/Location/Location";
import Timer from "./components/Timer/Timer";
import Footer from "./components/Footer/Footer";
import Gallery from "./components/Gallery/Gallery";
import SideBar from "./components/SideBar/sidebar";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import logo from "./assets/logo-big.png";

export default function App() {
  const [showSideBar, setShowSideBar] = useState(false);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <Header />
      <Header setShowSideBar={setShowSideBar} />
      {showSideBar && <SideBar />}
      <Hero />
      <Community />
      <Architecture />
      <Location />
      <Timer />
      <Gallery />
      <Footer />
      <FloatingWhatsApp
        phoneNumber="+233551929292"
        accountName="Merson Properties Limited"
        statusMessage="Typically replies within minutes"
        chatMessage="Hello there! 🤝, How can we help?"
        placeholder="Type a message ..."
        avatar={logo}
        allowEsc
        allowClickAway
        notification
        notificationSound
      />
    </div>
  );
}
