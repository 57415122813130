import React, { useState, useEffect } from "react";
import "./Architecture.css";
import aerial from '../../assets/ariel.png';
import orchid from '../../assets/orchid-front.png';
import hibiscus from '../../assets/hibiscus-front.png';
import shopping from '../../assets/retail-center2.png';
import playground from '../../assets/play-ground.png';

const Architecture = () => {
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    const id = setInterval(() => {
      setActiveCardIndex((prevIndex) => (prevIndex + 1) % 5);
      setActiveImageIndex((prevIndex) => (prevIndex + 1) % 5);
    }, 5000);

    setIntervalId(id);

    return () => clearInterval(id);
  }, []);

  // Function to handle card click
  const handleCardClick = (index) => {
    if (index !== 0) {
      setActiveCardIndex(index);
      setActiveImageIndex(index);

      // Clear the current interval and start a new one
      clearInterval(intervalId);

      const newIntervalId = setInterval(() => {
        setActiveCardIndex((prevIndex) => (prevIndex + 1) % 5);
        setActiveImageIndex((prevIndex) => (prevIndex + 1) % 5);
      }, 5000);

      setIntervalId(newIntervalId);
    }
  };

  const architectureCards = [
    {},
    {
      subHeader: "The Orchid",
      content:
        "A two-story building, where the ground floor accommodates the living spaces, and the first floor houses the master bedroom along with two additional bedrooms.",
    },
    {
      subHeader: "The Hibiscus",
      content:
        "A two-bedroom expandable home with the option to add third (master) bedroom on request, the ground floor hosts the living spaces and the first floor hosts the bedrooms.",
    },
    {
      subHeader: "Convenience Shopping",
      content:
        "A two-story building featuring a convenience store, a reliable pharmacy, and a cozy neighborhood cafe and an adaptable space that can be used for a gym, salon, or shop.",
    },
    {
      subHeader: "Kids Playground",
      content:
        "A dedicated children's playground thoughtfully equipped with fun amenities and safety in mind, situated on the other side of the entrance to the retail center and security gate",
    },
  ];

  const images = [aerial, orchid, hibiscus, shopping, playground];

  return (
    <section className="architecture" id="architecture">
      <div className="architectureContainer">
        <h2 className="header">Our Community Architecture</h2>
        <div className="architecture-wrapper">
          <div className="arch-card-wrapper">
            {architectureCards.map((card, index) => (
              <div
                className={`arch-card ${
                  index === activeCardIndex && activeImageIndex !== 0
                    ? "card-current"
                    : ""
                }`}
                key={index}
                onClick={() => handleCardClick(index)}
              >
                <p className="arch-sub-header">{card.subHeader}</p>
                <p className="content">{card.content}</p>
              </div>
            ))}
          </div>

          <div className="arch-slide-wrap">
            <img
              src={images[activeImageIndex]}
              className="arch-image"
              alt="Gated Community Aerial View"
            />

            <div className="pagination-dots">
              {architectureCards.map((_, index) => (
                <div
                  key={index}
                  className={`dot ${index === activeCardIndex ? "dot-current" : ""}`}
                ></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Architecture;
