import React, { useState, useEffect } from 'react';
import './Gallery.css';
import ImageModal from '../ImageModal/ImageModal'; // Import the ImageModal component
import aerial from '../../assets/ariel.png';
import orchid from '../../assets/orchid-front.png';
import orchidSide from '../../assets/orchid-side.png';
import playground from '../../assets/play-ground.png';
import retail from '../../assets/retail-center1.png';
import retailB from '../../assets/retail-center2.png';
import auditorium from '../../assets/auditourium.png';
import hibiscus from '../../assets/hibiscus-front.png';
import hibiscusFront from '../../assets/hibiscus-front2.png';
import hibiscusRear from '../../assets/hibiscus-rear.png';
import hibiscusAriel from '../../assets/hibiscus-ariel.png';
import arrowLeft from '../../assets/arrow-left.png'; 
import arrowRight from '../../assets/arrow-right.png'; 

const Gallery = () => {

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  

  useEffect(() => {
    scrollToCurrentImage();
  }, [currentImageIndex]);


  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const scrollToCurrentImage = () => {
    const currentImage = document.querySelector('.current');
    if (currentImage) {
      currentImage.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  };

  const images = [
    aerial,
    orchid,
    orchidSide,
    playground,
    retailB,
    retail,
    auditorium,
    hibiscus,
    hibiscusFront,
    hibiscusRear,
    hibiscusAriel,
  ];

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <section className="gallery" id="gallery">
      <div className="galleryContainer">
        <h2 className="header">Our Gallery</h2>
        <div className="slider">
          <div className="gallery-wrapper">
            <div className="image-wrapper">
              {images.map((src, index) => (
                <img
                  key={index}
                  src={src}
                  alt={`Images of a gated community in accra,ghana ${index + 1}`}
                  className={index === currentImageIndex ? 'current' : 'slide'}
                  onClick={() => {
                    setCurrentImageIndex(index); // Set the clicked image as the current image
                    openModal();
                  }}
                />
              ))}
            </div>
          </div>

          <div className="button-wrapper">
            <button
              type="button"
              className="previous"
              onClick={prevImage}
              disabled={currentImageIndex === 0}
            >
              <img src={arrowLeft} alt="Previous" />
            </button>

            <button
              type="button"
              className="next"
              onClick={nextImage}
              disabled={currentImageIndex === images.length - 1}
            >
              <img src={arrowRight} alt="Next" />
            </button>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <ImageModal
          image={images[currentImageIndex]}
          onClose={closeModal}
          onNext={nextImage}
          onPrevious={prevImage} 
        />
      )}
    </section>
  );
};

export default Gallery;
