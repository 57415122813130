import React, { useState, useEffect } from 'react';
import './ImageModal.css'; 
import close from '../../assets/close-lightbox.png';
import arrowLeft from '../../assets/arrow-circle-left.png'; 
import arrowRight from '../../assets/arrow-circle-right.png'; 

const ImageModal = ({ image, onClose, onNext, onPrevious }) => {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflow = 'hidden'; // Disable scrolling when modal is open
    } else {
      document.body.style.overflow = 'auto'; // Enable scrolling when modal is closed
    }
  }, [modalOpen]);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={`image-modal-overlay ${modalOpen ? 'open' : ''}`} onClick={closeModal}>
      <div className='close-btn-wrapper'>
        <button className="close-button" onClick={onClose}>
          <img src={close} className='lightbox-close' alt="Close" />
          close
        </button>
      </div>

      <div className='lightbox-content'>
        <div className="image-modal" onClick={stopPropagation}>
          <img src={image} alt="Modal" onClick={openModal} />
        </div>

        <div className="circleBtns">
          <button
            type="button"
            className="previous"
            onClick={onPrevious}
          >
            <img src={arrowLeft} alt="previous" />
          </button>

          <button
            type="button"
            className="next"
            onClick={onNext}             
          >
            <img src={arrowRight} alt="Next"/>
          </button>
        </div>
      </div>

    </div>
  );
};

export default ImageModal;
