import React from "react";
import "./Location.css";

const Location = () => {
  return (
    <section className="location" id="location">
      <div className="locationContainer">
        <h2 className="header">Our Beautiful Location</h2>
            <div className="location-wrapper">
                <div className="map">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3969.1181754297904!2d-0.12314292587168282!3d5.838962830741246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNcKwNTAnMjAuMyJOIDDCsDA3JzE0LjAiVw!5e0!3m2!1sen!2sgh!4v1695395206677!5m2!1sen!2sgh" width="100%" height="100%" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className="cards-container">
                    <div className="loc-card">
                        <p className="loc-sub-header">Strategic Location</p>
                        <p className="description">Our gated community is situated in Oyibi, just 500 meters away from the main Accra-Dodowa road, offering the advantage of being a short drive (less than 5 minutes) from Valley View University.</p>
                    </div>
                    
                    <div className="loc-card">
                        <p className="loc-sub-header">Emerging Residential Hub</p>
                        <p className="description">Oyibi is an emerging mid-market residential area in Greater Accra, characterized by it’s growth. It hosts multiple residential real estate developments, signifying its potential as a thriving housing market.</p>
                    </div>

                    <div className="loc-card">
                        <p className="loc-sub-header">Investment Potential</p>
                        <p className="description">Oyibi is positioned to be the next 'Spintex Road,' known for property value appreciation, making it an appealing option for real estate investment and future returns.</p>
                    </div>
                </div>
            </div>
      </div> 
    </section>
    
  );
};

export default Location;
