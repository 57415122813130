import React, { useState } from "react";
import "./sidebar.css";
import close from '../../assets/close-sidebar.png'
import logo from '../../assets/Harvey Properties White 1.png'

function SideBar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <div>
      {isSidebarOpen && (
        <div className="sidebar-overlay">
          <div className="sidebar">
            <div className="sidebar-top">
              <button type="button" className="close-sidebar" onClick={toggleSidebar}>
                <img src={close} className="btn-img" alt="Close Sidebar" />
              </button>
              <div className="menu-logo-wrap">
                <img src={logo} className="menu-logo" alt="Company Logo" />
              </div>
              <div className="sidebar-links">
                <div className="menu-link-wrapper" onClick={closeSidebar}>
                  <a href="#hero">Home</a>
                </div>
                <div className="menu-link-wrapper" onClick={closeSidebar}>
                  <a href="#community">Community</a>
                </div>
                <div className="menu-link-wrapper" onClick={closeSidebar}>
                  <a href="#architecture">Architecture</a>
                </div>
                <div className="menu-link-wrapper" onClick={closeSidebar}>
                  <a href="#location">Location</a>
                </div>
                <div className="menu-link-wrapper" onClick={closeSidebar}>
                  <a href="#gallery">Gallery</a>
                </div>
              </div>
            </div>

            <div className="sidebar-bottom">
              <a href="mailto:mpl@mersoncapital.com" className="button">
                Contact Us
              </a>
            </div>
          </div>
        </div>
      )}
      
    </div>
  );
}

export default SideBar;
