import React from "react";
import "./Footer.css";
import logo from "../../assets/white-logo.png";
import instagram from "../../assets/instagram-icon.png";
import twitter from "../../assets/twitter-icon.png";
import linkdin from "../../assets/linkdin-icon.png";

function Footer() {
  return (
    <section className="footer">
      <div className="topHalf">
        <div className="getNotified footerItem">
          <p className="footerHeader">GET NOTIFIED</p>
          <div className="form-wrapper">
            <p>
              Stay in the loop with updates on the progress of our project.
              Enter your email below, and you’re all set.
            </p>
            <form method="POST" action="#">
              <div className="input-wrapper">
                <input type="email" placeholder="Enter your email" required />
                <button type="submit" className="sendBtn">
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="company footerItem">
          <p className="footerHeader">COMPANY</p>
          <div className="links">
            <a href="https://hpl.mersoncapital.com/blog" target="_blank">
              Blog
            </a>
            <a href="https://hpl.mersoncapital.com/about" target="_blank">
              About the Company
            </a>
            <a href="https://hpl.mersoncapital.com/brokerage" target="_blank">
              Completed Projects
            </a>
          </div>
        </div>
        <div className="usefulLinks footerItem">
          <p className="footerHeader">USEFUL LINKS</p>
          <div className="links">
            <a href="https://theavenuegh.com/" target="_blank">
              59 Lagos Avenue
            </a>
            <a href="http://gclgh.com/" target="_blank">
              Greycrest Construction
            </a>
            <a href="http://mersonproperties.com/" target="_blank">
              Merson Properties Limited
            </a>
          </div>
        </div>
        <div className="contactUs footerItem">
          <p className="footerHeader">CONTACT US</p>
          <div className="links">
            <a href="tel:+233 55 192 9292">+233 55 192 9292</a>
            <a href="mailto:mpl@mersoncapital.com" target="_blank">
              mpl@mersoncapital.com
            </a>
          </div>
        </div>
      </div>
      <div className="bottomHalf">
        <hr />
        <div className="belowLine">
          <img src={logo} className="logo" alt="company logo" />
          <p>© 2023 Harvey Village.</p>
          <div className="socials">
            <a href="https://www.linkedin.com/company/merson-properties-management-ltd/" target="_blank">
              <img src={linkdin} alt="linkdin logo" />
            </a>

            <a href="https://instagram.com/merson_properties?igshid=MzRlODBiNWFlZA==" target="_blank">
              <img src={instagram} alt="instagram logo" />
            </a>

            <a href="https://x.com/mersonprop?s=21&t=ExscwKszOS62Qa9u6cwXTg" target="_blank">
              <img src={twitter} alt="twitter logo" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

// commit
export default Footer;
