import React from "react";
import "./Header.css";
import logo from '../../assets/Harvey Properties White 1.png';
import menu from '../../assets/menu.png';

const Header = ({ setShowSideBar }) => {
  return (
    <nav className="navbar">
      <div className="logoDiv">
        <img src={logo} alt="company logo" className="navLogo" />
      </div>
      <div className="navMenu">
        <ul className="menuList">
          <div className="menuLinks">
            <li>
              <a href="#hero" className="nav-link">
                Home
              </a>
            </li>
            <li>
              <a href="#community" className="nav-link">
                Community
              </a>
            </li>
            <li>
              <a href="#architecture" className="nav-link">
                Architecture
              </a>
            </li>
            <li>
              <a href="#location" className="nav-link">
                Location
              </a>
            </li>
            <li>
              <a href="#gallery" className="nav-link">
                Gallery
              </a>
            </li>
          </div>
          <li>
            <a href="mailto:mpl@mersoncapital.com" className="button">
              Contact Us
            </a>
          </li>
        </ul>
      </div>
      <button
        type="button"
        className="menu-btn"
        onClick={() => setShowSideBar((p) => !p)}
      >
        <img src={menu} alt="menu icon" />
      </button>
    </nav>
  );
};

export default Header;
