import React from "react";
import "./Community.css";
import tv_icon from '../../assets/Entertainment.png';
import shield_icon from '../../assets/Secure.png';
import car_icon from '../../assets/AmpleParking.png';
import people_icon from '../../assets/FamilyFriendly.png';
import shop_icon from '../../assets/Retail.png';
import building_icon from '../../assets/Infrastructure.png';

const Community = () => {
  return (
    <section className="community" id="community">
      <div className="communityContainer content">
        <h2 className="header">What Sets Our Community Apart?</h2>
        <div className="commCardsContainer">  {/* container for all cards in this section */}
          <div className="commCard">
            <img src={shield_icon} className="cardImage" alt="Shield Icon" />
            <div className="cardTxt">
              <p className="cardHeader">Secure Community</p>
              <p className="cardContent">55 beautiful homes within a secure, walled and gated community with lush greenery.</p>
            </div>
          </div>

          <div className="commCard">
            <img src={car_icon} className="cardImage" alt="Car Icon" />
            <div className="cardTxt">
              <p className="cardHeader">Ample Parking</p>
              <p className="cardContent">Minimum of two car parking spaces per home.</p>
            </div>
          </div>

          <div className="commCard">
            <img src={people_icon} className="cardImage" alt="People Icon" />
            <div className="cardTxt">
              <p className="cardHeader">Family Friendly</p>
              <p className="cardContent">Kid’s play garden featuring swings, slides and other outdoor activities.</p>
            </div>
          </div>

          <div className="commCard">
            <img src={tv_icon} className="cardImage" alt="TV Icon" />
            <div className="cardTxt">
              <p className="cardHeader">Entertainment Hub</p>
              <p className="cardContent">Rooftop amphitheater, a versatile space perfect for hosting a variety of events.</p>
            </div>
          </div>

          <div className="commCard">
            <img src={shop_icon} className="cardImage" alt="Shop Icon" />
            <div className="cardTxt">
              <p className="cardHeader">Retail Center</p>
              <p className="cardContent">A community retail center with a convenience store, pharmacy,  salon and a gym, to enhance your quality of life.</p>
            </div>
          </div>

          <div className="commCard">
            <img src={building_icon} className="cardImage" alt="Building Icon" />
            <div className="cardTxt">
              <p className="cardHeader">Infrastructure</p>
              <p className="cardContent">About 1 km of engineered and surfaced roads and a borehole water supply back-up system.</p>
            </div>
          </div>
          
        </div>
      </div> 
    </section>
    
  );
};

export default Community;
